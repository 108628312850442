import React, { useContext, useEffect, useRef, useState } from "react";
import { ChosenCard } from "../../Components/ChosenCard/ChosenCard";
import SubScribersTable from "../../Components/SubscribersTable/SubScribersTable";
import { FireBaseContext } from "../../Context/FireBase";
import { useParams } from "react-router-dom";
import { query, where, onSnapshot, getDoc, doc } from "firebase/firestore";

export const Subscribers = () => {
  const {
    setSubscribers,
    Subscribers,
    SubscribersRefrence,
    UserRef,
    EventRefrence,

  } = useContext(FireBaseContext);

  const { dbID } = useParams();
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const itemRef = doc(EventRefrence, dbID);
    (async () => {
      const datas = await getDoc(itemRef);
      const Result = await datas.data();
      setEventData(Result);
    })();

  }, [dbID])

  useEffect(() => {
    if (!dbID || !SubscribersRefrence) {
      return;
    }

    const q = query(SubscribersRefrence, where("eventID", "==", dbID));
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const subscribersData = querySnapshot.docs.map((doc) => (
          {
            ID: doc.id,
            ...doc.data(),
          }
        ));

        setSubscribers(subscribersData);
      },
      (error) => {
        console.error("Error fetching subscribers:", error);
      }
    );


    return () => unsubscribe();
  }, [dbID]);

  useEffect(() => {
    if(Subscribers.length > 0 && eventData){
      Subscribers.forEach((subscriber) => {
        subscriber.eventDate = eventData.eventDate
      })
    }
  }, [Subscribers])

  return (
    <div className="EventsPageParent d-flex flex-column container-fluid container-md gap-3">
      <h2>Event Details</h2>
      <div className="d-flex justify-content-center">
        <ChosenCard />
      </div>
      <h2>Subscribers</h2>
      <SubScribersTable row={Subscribers} refCollection={SubscribersRefrence} />
    </div>
  );
};
