import React, { useContext, useEffect, useState, useRef } from "react";
import DataTable from "../../Components/DataTable/DataTable";
import { FireBaseContext } from "../../Context/FireBase";
import SearchFormik from "../../Components/SearchFormik/SearchFormik";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { Link } from "react-router-dom";

export const Events = () => {
  const {
    events,
    getData,
    setEvents,
    EventRefrence,
    currentUserRole,
    eventsQueryAccordingToUserRole,
    UserRef,
    SubscribersRefrence,
  } = useContext(FireBaseContext);

  const [informations, setInformations] = useState([]);
  const [rows, setRows] = useState([]);
  const [startDateFilter, setStartDateFilter] = useState(""); // Moved to parent
  const [endDateFilter, setEndDateFilter] = useState("");
  const [eventsSubscriber, setEventsSubscriber] = useState([]);




  const prevEventsRef = useRef(events);

  // Fetching event information based on user role
  useEffect(() => {
    if (currentUserRole) {
      getData(eventsQueryAccordingToUserRole(), setInformations);
    }
  }, [currentUserRole, getData, eventsQueryAccordingToUserRole]);

  // Process fetched event information
  useEffect(() => {
    const fetchDataForItems = async () => {
      const date = new Date().getTime();
      const promises = informations.map(async (item) => {
        // const data = doc(EventRefrence, item.Id.toString());
        // const eventSubscribersCollec = collection(data, "Subscribers");
        // const subNm = await getDocs(eventSubscribersCollec);
        // const NumberOfSubScribers = subNm.docs.length;
        const EndTime = new Date(item.EndDate).getTime();
        const StartTime = new Date(item.StartDate).getTime();
        const subscribersQuery = query(
          SubscribersRefrence,
          where("eventID", "==", item.Id.toString())
        );
        const querySnapshot = await getDocs(subscribersQuery);
        const subscribers = [];
        querySnapshot.forEach((doc) => {
          subscribers.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        const totalCost = subscribers.reduce(
          (total, subscriber) => total + subscriber.CostperDelegate,
          0
        );
        item.EventCost = totalCost;

        let status = item.Status;
        if (StartTime > date) {
          status = "Pending";
        } else if (date > StartTime && EndTime > date) {
          status = "Started";
        } else if (date > EndTime) {
          status = "Completed";
        }
        item.Status = status;

        const userSnapshot = await getDoc(doc(UserRef, item.CreatedByID));
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          item.CreatedBy = userData; // Store the whole user object
        } else {
          item.CreatedBy = "Unknown";
        }

        return item;
      });

      const results = await Promise.all(promises);

      // Only update context if the new data is different
      if (
        prevEventsRef.current.length !== results.length ||
        !prevEventsRef.current.every(
          (event, idx) =>
            event.Id === results[idx].Id && event.Status === results[idx].Status
        )
      ) {
        setEvents(results.reverse());
        prevEventsRef.current = results.reverse();
      }
    };

    if (informations.length > 0) {
      fetchDataForItems();
    }
  }, [informations, EventRefrence, UserRef, setEvents]);
  return (
    <div className="d-flex flex-column container-fluid container-md gap-3 EventsPageParent">
      <h2>Events</h2>
      {!informations.length ? (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "calc(100vh - 150px)" }}
        >
          <p>Create Your First Event</p>
        </div>
      ) : (
        <>
          <SearchFormik
            rows={events}
            setRows={setRows}
            startDateFilter={startDateFilter}
            endDateFilter={endDateFilter}
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
          
          />
          <DataTable row={rows} startDateFilter={startDateFilter}
            endDateFilter={endDateFilter}  />
        </>
      )}
    </div>
  );
};
