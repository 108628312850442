import { confirmPasswordReset, getAuth } from "firebase/auth";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import leftLogo from "../../assets/LoadingLogo.png";
import rightlogo from "../../assets/Orevan.png";
import center from "../../assets/Asset 1@4x.png";
import eventLogo from "../../assets/LoginPage.png";
import { Formik } from "formik";

function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();



  const auth = getAuth();
  function resetPassword(oobCode, newPassword) {
    return confirmPasswordReset(auth, oobCode, newPassword);
  }

  const query = useQuery();

  const onSubmit = (e) => {
    e.preventDefault();
    
    resetPassword(query.get("oobCode"), newPassword)
 
      .then((response) => {
        console.log(response);
        toast.success("Password Updated Successfully , you can login now", {
          autoClose: 4000,
        });
        setTimeout(() => {
          navigate("/");
        }, 4000);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          autoClose: 4000,
        });
      });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex w-full h-screen">
      <ToastContainer />
      <div className="w-full flex items-center justify-center lg:w-1/2">
        <div className="bg-white px-10 py-20 rounded-3xl border-2 border-gray-100 shadow xl:w-3/5 lg:w-3/4 md:w-3/5">
          <div className="flex justify-center">
            <img
              src={center}
              alt=""
              className="w-50"
              style={{ objectFit: "contain" }}
            />
          </div>
          <h2 className="font-medium text-2xl text-gray-500 mt-4  text-center">
            Reset Password
          </h2>
          <div className="mt-8">
            <Formik>
              {() => (
                <form>
                  <div>
                    <label className="text-lg font-medium my-3" htmlFor="">
                      New Password
                    </label>
                    <div className="relative w-full">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter Your New Password.."
                        className="w-full border border-gray-100  p-3  bg-transparent"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <i
                        className={`fa-regular ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        } absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer`}
                        onClick={togglePassword}
                      ></i>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-center align-items-center"></div>
                  </div>
                  <div className="mt-8 flex flex-col gap-y-4">
                    <button
                      onClick={onSubmit}
                      type="submit"
                      className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-gradient-to-tr from-orangeColor to-yellowColor text-white text-lg font-bold w-100 flex justify-center items-center gap-3"
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div className="hidden relative lg:flex h-full w-1/2 items-center justify-center flex-col bg-gray-100 ">
        <div className="mt-[-3vw] mr-[15vw]">
          <div className="w-[18vw] h-[18vw] bg-gradient-to-tr from-blueColor to-blue-500 rounded-full animate-spin"></div>
        </div>
        <div className="mt-[-12vw] z-10">
          <img className="w-[20vw]" src={eventLogo} alt="" />
        </div>
        <div className="mt-[-10vw] ml-[8vw]">
          <div className="w-[18vw] h-[18vw] bg-gradient-to-tr from-orangeColor to-yellowColor rounded-full animate-spin"></div>
        </div>

        <div className="w-full  absolute bottom-0 bg-white/10 backdrop-blur-lg flex justify-end flex-col ">
          <div className="grid  lg:grid-cols-4  xl:grid-cols-6 gap-x-32 lg:px-4 xl:px-10 mb-4 ">
            <div className="lg:col-span-2 xl:col-span-3 ">
              <img className="w-100" src={leftLogo} alt="" />
            </div>
            <div className="lg:col-span-2 xl:col-span-3  flex justify-end">
              <img
                src={rightlogo}
                className="w-50"
                alt=""
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

{
  /* <div className="container h-screen w-screen flex justify-center items-center ">
<ToastContainer />
<div >
  <h2 className="text-center">Reset Password</h2>
  <form action="" className="border border-gray-50 shadow p-4 mt-4" style={{width:"500px"}}>
    <label className="block text-black text-lg font-bold mb-2">
      New password
    </label>
    <input
      type="password"
      value={newPassword}
      onChange={(e)=> setNewPassword(e.target.value)}
      className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
    />
    <div className="flex justify-center">
    <button onClick={onSubmit}  className="text-white bg-blueColor p-2 my-4 rounded w-50">Submit</button>
    </div>
  </form>
</div>
</div> */
}
